<template>
    <div>
      <main>
        <!-- 麵包屑 -->
        <section class="container" data-aos="fade-down"  data-aos-delay="500">
          <nav style="--bs-breadcrumb-divider: '>'" aria-label="breadcrumb" class="badge">
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="/">首頁</a></li>
              <li class="breadcrumb-item"><a href="#/unintended-2">我意外懷孕了</a></li>
              <li class="breadcrumb-item"><a href="#/unintended-3-1">我還沒準備好生下來</a></li>
              <li class="breadcrumb-item" aria-current="page"><a href="#">醫療議題</a></li>
              <li class="breadcrumb-item active" aria-current="page"><a href="#">人工流產</a></li>
            </ol>
          </nav>
        </section>

        <!-- 主要區塊 -->
        <section class="container step-rwd">
          <div class="step">
            <!-- 裝飾用圖片 -->
            <img class="step-circle" src="../../assets/image/front/image_circleword.svg">
            <img class="image1" src="../../assets/image/front/page02_image1.svg">
            <img class="image2" src="../../assets/image/front/page02_image2.svg">
            <img class="image3" src="../../assets/image/front/page02_image3.svg">
            <!-- 結束 -->
            <div class="title-box">
              <p class="step-text" data-aos="fade-up" >ANSEWR</p>
              <h2 class="step-title" data-aos="fade-up" >選擇・答案</h2>
              <img class="title-line" src="../../assets/image/front/choose_title_line.svg" data-aos="fade-up" >
              <p class="step-sologan" data-aos="fade-up" >為自己所想，為自己所選。</p>
            </div>
            <div class="container step-box">
              <div class="step-box-inner">
                <div class="box-title" data-aos="fade-up">
                  <h2>｜人工流產｜</h2>
                </div>
                <div class="select-menu">
                  <img class="mb-60" data-aos="fade-up" src='../../assets/image/front/page02_pic3.jpg'>
                  <hr data-aos="fade-up">
                    <!-- 選項開始 -->
                    <ul class="select-item container-inner mt-60">
                      <li data-aos="fade-up">
                        <h3>什麼是終止懷孕？</h3>
                        <p>終止懷孕又可稱為墮胎、人工流產、終止妊娠。可以透過手術或口服藥物的方式，將胚胎從子宮內移除。隨著懷孕時間的長短不同會有不同的執行療程和影響，以及法律規範。若透過合法管道進行醫療流程，會有醫師給予適時的檢查與手術，可以降低風險。反之，若沒有依循合法正常的途徑或太晚執行終止懷孕，很有可能會對身體留下永久的傷害。<br>
                        <img class="rounded-0 w-100 pt-2" src="https://www.taog.org.tw/img/ill/12.jpg" alt=""><br>《圖片來源：台灣婦產科醫學會》</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>執行終止懷孕的相關手術或療程有需要住院嗎？</h3>
                        <p>如果是在12週以內能夠在相關的醫院或診所進行，但如果超過12週後則需要依照優生健保法住院進行。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>如果孩子曾到醫院或診所單純就診檢查會通知父母嗎？</h3>
                        <p>如果是未滿18歲的未成年人士，只是單純就診，並不會通知父母或主管機關；但如果確定要執行終止懷孕的相關手術及療程就會需要法定代理人或配偶的同意。但是，如果你是未滿16歲的未成年人士，由於在法律中你的身分仍屬於無行為能力者，所以有關重要的事(就學、就醫等等)，皆需法定監護人協助與陪同。所以當你獨自就診時，醫院就有需要通知你的監護權人或主管機關來幫忙。主管機關的角色是來協同你將此事處理得更完整，或確保你的權益不會因為年紀、身分而受損。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>健保有給付終止懷孕的相關費用嗎？</h3>
                        <p>如果手術需求是經由醫院檢查確定有符合上述情況的話，手術費用會由健保給付；但是如果是由自己提出的話則需要由自己負擔。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>可以到哪些醫院或診所進行手術？</h3>
                        <p>若需要終止初期懷孕的服務，建議可以直接到<a href="https://www.hpa.gov.tw/Pages/Detail.aspx?nodeid=518&pid=552" target="_blank">「青少年親善醫師門診名單」</a>，找尋離你最近，或是讓你最安心的醫師為你診斷。為了提供青少年良好的醫療環境，專為未滿18歲之青少年、以及20~24歲青年創立親善門診。溫馨的候診空間，讓你安心不焦慮，也非常注重你的隱私權，門診費用都是青少年可負擔得起，若有需要也可以協助你與父母進行溝通。 </p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>沒有錢可以進行手術怎麼辦？</h3>
                        <p>目前政府的人工流產補助計劃，只限於沒有健保卡並且是被強制性交、誘姦而受孕之婦女，補助金額最高3000元。若你不符合上述情形，建議你到地方社會局或是社福機構，向他們訴說你的狀況，社工將會依照你的情況，連結適當的資源，千萬不要胡亂吃藥或是尋找密醫，安全和健康才是最重要的。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>我不敢讓爸媽知道怎麼辦？</h3>
                        <p>若你選擇終止懷孕，不管是以吃藥還是手術的方式，只要你未滿18歲都需要法定代理人的同意，
                          任何人都不可以代簽。但若你擔心父母知道後的反應是你無法因應的，
                          你可以向校方、社會局或社福機構（如勵馨基金會：<a class="fs-5 text-danger" href="https://257085.sfaa.gov.tw" target="_blank" title="未成年懷孕求助站">未成年懷孕求助網站| 257085.sfaa.gov.tw</a>）尋求協助，向他們訴說你的狀況，讓他們了解家裡的情況以及與家人的關係，社工可以協助你與父母溝通，安撫父母一時之間無法接受的情緒，陪伴你一起面對懷孕的事件。</p>
                      </li>
                      <li data-aos="fade-up">
                        <h3>全台青少年親善門診名單：（過去曾提供服務）</h3>
                        <p>青少年親善門診強調以尊重、保護及符合青少年權利之心態提供資訊，注重隱私與機密性、保持中立友善、不論斷之心態服務青少年。<br>
                        如您想尋求專業的醫學建議，可至您附近的青少年親善門診諮詢。<br><br>
                        相關連結：<a class="fs-5 text-danger" href="https://www.hpa.gov.tw/Pages/Detail.aspx?nodeid=518&pid=14727" target="_blank">國民健康署網站</a><br>
                        </p>
                        <!--<div class="google-rwd">
                          <iframe src="https://www.google.com/maps/d/embed?mid=19MVZJgDLbwL-5JUn7Cpr3cgSS4XGIVw1" width="640" height="480"></iframe>
                        </div>-->
                      </li>
                    </ul>
                    <!-- 選項結束 -->
                    <hr data-aos="fade-up">
                    <div data-aos="fade-up">
                      <a class="goBack" onclick="history.back()" value="回到上一頁">回到上一頁</a>
                      <a class="goBack" href="#">返回首頁</a>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Consult></Consult>
        <Circle></Circle>
      </main>
    </div>
</template>
<script>
import Consult from '../../components/Consult.vue'
export default {
  data () {
    return {
    }
  },
  mounted () {
  },
  components: {
    Consult
  }
}
</script>
